<template>
  <div :class="$style.root">
    <v-form ref="form" v-model="valid" :class="$style.root">
      <task-status-switch class="mb-6" :value="status" :taskType="task.type" :disabled="!modified || !valid || (!validRealizations && !advanceDiscount) || (advanceDiscount && !files.length)" @input="onSubmit" @reset="onReset" />
      <v-alert v-if="error" class="mb-6" dense outlined type="error">
        <div v-html="error.error_description" />
      </v-alert>
      <task-form-row
          v-if="task.type"
          title="Тип"
          :value="[task.type.name]"
          disabled
      />
      <task-form-row
          title="ИНН"
          v-model="fields.inn"
          :component="isCreate ? 'inn-field': null"
          :check="['required', 'inn', 'not', 'duplicate', 'notRelated']"
          :disabled="!isCreate"
          @update="onUpdate"
      />
      <task-form-row
          title="Клиент"
          v-model="fields.name"
          :rules="[rules.required]"
          :disabled="!((isAdmin || isCreate) || isAdjustment) || viewOnly"
      />
      <task-form-row
          title="Адрес"
          v-model="fields.address"
          :rules="[rules.required]"
          :disabled="!((isAdmin || isCreate) || isAdjustment) || viewOnly"
      />
      <task-form-row
          title="Телефон"
          v-model="fields.phone"
          :disabled="!((isAdmin || isCreate) || isAdjustment) || viewOnly"
      />
      <task-form-row
          title="Сайт"
          v-model="fields.site"
          :disabled="!((isAdmin || isCreate) || isAdjustment) || viewOnly"
      />
      <task-form-row
          title="Классификация"
          v-model="fields.classification"
          :rules="[rules.required]"
          :items="classifications"
          :disabled="!((isAdmin || isCreate) || isAdjustment) || viewOnly"
      />
      <task-view-row
              title="Классификация ОКВЭД (справочная)"
              v-if="correct"
              :value="classificationOkvedValue"/>
      <task-form-row
              title="Дополнительная классификация"
              v-model="fields.ckg"
              :rules="isAdmin ? [rules.required] : []"
              :items="ckgListByClassification"
              v-if="isAdmin"
      />
      <task-form-row
          title="ОКВЭД"
          v-model="fields.okved"
          :disabled="isCreate"
      />
      <task-form-row
          v-if="fields.source"
          title="Источник"
          :value="fields.source"
      />
      <task-form-row
          title="Статус"
          v-model="fields.statusClient"
          :rules="[rules.required]"
          :items="statusesByClassification"
          :disabled="viewOnly"
      />
      <task-form-row
          title="Установить авансовую скидку"
          component="v-checkbox"
          :input-value="task.advanceDiscount[0]"
          :value="task.advanceDiscount"
          v-if="showAdvanceDiscountCheckbox"
          @change="onUpdateAdvanceDiscount"
      />
      <task-form-row
          v-if="(isCreate || isEditing) && !viewOnly"
          title="Комментарий"
          component="v-textarea"
          v-model="fields.comment"
          :disabled="viewOnly"
      />
      <panel title="Документы">
        <file-table v-if="viewOnly" />
        <file-list v-else :value="task.files" @input="onUpdateFiles" />
        <v-alert v-if="showAdvanceDiscountCheckbox && advanceDiscount && !files.length" class="mt-5 mb-0" dense outlined type="error">
          <div>Приложите документы (протоколы)</div>
        </v-alert>
      </panel>
      <panel-difference title="Связанные клиенты" :items="fields.clients">
        <template v-slot:default="{ items, index, length }">
          <client-table v-if="viewOnly || (length > 1 && !index)" :value="items" />
          <client-attach-table v-else v-model="fields.clients[index]" :current="values.inn" />
        </template>
      </panel-difference>
      <panel-difference title="Скидки" :items="fields.sales">
        <template v-slot:default="{ items }">
          <client-discount-table :value="items" />
        </template>
      </panel-difference>
      <panel-difference v-if="isAdmin" title="Дистрибьюторы" :items="fields.distributors">
        <template v-slot:default="{ items, index, length }">
          <client-distributor-table v-if="viewOnly || (length > 1 && !index)" :value="items" />
          <distributor-attach-table v-else v-model="fields.distributors[index]" />
        </template>
      </panel-difference>
    </v-form>
    <panel v-if="viewRealization" title="Продажи">
      <realization-distributor v-if="isAdmin" :inn="inn" />
      <realization-attach-table v-else :inn="inn" :required="isCreate && !isAdmin && !advanceDiscount" />
    </panel>
  </div>
</template>

<script>
import { get, head, toNumber, map, filter, includes, last, toString, compact } from 'lodash';
import client from '@/store/clients/api';
import { enumerationAdapter } from '@/store/utils';
import { clientItemAdapter } from '@/store/clients/utils';
import { taskEmptyAdapter } from '@/store/tasks/utils';
import STATUSES from '@/api/enums/statuses';
import { mapGetters } from 'vuex';

import TaskForm from '@/mixins/TaskForm/TaskForm';
import Panel from '@/components/Panel/Panel';
import FileList from '@/components/FileList/FileList';
import FileTable from '@/components/FileTable/FileTable';
import TaskFormRow from '@/components/TaskFormRow/TaskFormRow';
import TaskViewRow from '@/components/TaskViewRow/TaskViewRow';
import ClientTable from '@/components/ClientTable/ClientTable';
import PanelDifference from '@/components/PanelDifference/PanelDifference';
import TaskStatusSwitch from '@/components/TaskStatusSwitch/TaskStatusSwitch';
import ClientAttachTable from '@/components/ClientAttachTable/ClientAttachTable';
import ClientDiscountTable from '@/components/ClientDiscountTable/ClientDiscountTable';
import ClientDistributorTable from '@/components/ClientDistributorTable/ClientDistributorTable';
import DistributorAttachTable from '@/components/DistributorAttachTable/DistributorAttachTable';
import RealizationAttachTable from '@/components/RealizationAttachTable/RealizationAttachTable';
import RealizationDistributor from '@/components/RealizationDistributor/RealizationDistributor';

export default {
  name: 'TaskForm',
  mixins: [ TaskForm ],
  components: {
    Panel,
    FileList,
    FileTable,
    TaskFormRow,
    TaskViewRow,
    ClientTable,
    PanelDifference,
    TaskStatusSwitch,
    ClientAttachTable,
    ClientDiscountTable,
    ClientDistributorTable,
    DistributorAttachTable,
    RealizationAttachTable,
    RealizationDistributor,
  },
  props: {
    taskType: { type: String }
  },
  data() {
    return {
      statusesByClassification: this.statuses,
      correct: false,
      inner: false,
      related: false,
      innClassesOkved: [],
      advanceDiscount: false,
    };
  },
  computed: {
    ...mapGetters({
      ckgList: 'clients/ckgList',
    }),
    viewOnly() {
      return !!(this.isCreate && ((this.inner && !this.related) || (this.related))) ;
    },
    viewRealization() {
      const length = includes([10, 12], toString(this.inn).length);
      return this.isCreate ? (this.isCreate && this.correct && length) : length;
    },
    classificationOkvedValue() {
      if(this.innClassesOkved && this.innClassesOkved.length) {
        const currentClass=this.classifications.find((el) => el.code===this.innClassesOkved[0]);
        return [currentClass.name];
      }
      else
        return ['отсутствует'];
    },
    isAdjustment() {
      return includes([STATUSES.ADJUSTMENT], this.status);
    },
    showAdvanceDiscountCheckbox() {
      return !this.inner && ((this.taskType==='client' && !this.fields.advanceDiscountPeriod[0]) || this.taskType==='task');
    },
    ckgListByClassification() {
      const currentClass=this.classifications.find((el) => el.code===this.fields.classification[0]);
      if(currentClass) {
        return this.ckgList.filter((ckg) => ckg.ufClassification === currentClass.id);
      }
      else {
        return [];
      }
    }
  },
  mounted() {
    if (this.isAdmin) {
      this.validateOnMount();
    }
  },
  methods: {
    onUpdate(items) {
      const item = head(items) || {};
      const { name, phone, address, okved } = item;
      const code = item.code || this.values.inn;
      this.correct = !!get(item, 'code', false);
      this.inner = get(item, 'inner', false);
      this.related = get(item, 'related', false);
      this.innClassesOkved = get(item, 'innClassesOkved', []);
      if (this.isCreate) {
        this.reset();
        this.$refs.form.validate();

        code && this.$set(this.fields.inn, 0, code);
        name && this.$set(this.fields.name, 0, name);
        phone && this.$set(this.fields.phone, 0, phone);
        address && this.$set(this.fields.address, 0, address);
        okved && this.$set(this.fields.okved, 0, okved);

        if (this.inner && code) {
          client.item(code).then(result => {
            const client = taskEmptyAdapter(clientItemAdapter(result.result));
            if (client.fields) {
              this.fields = client.fields;
            }
          })
        }
        else {
          if(this.innClassesOkved.length) {
            this.fields.classification = this.innClassesOkved;
          }
        }

        this.$snack({
          text: `Клиент ${['не', ''][toNumber(this.inner)]} найден в базе iPrice`,
          pos: 'top-center',
          actionText: 'Понятно',
        });
      }
    },
    onSubmit(status) {
      this.$refs.form.validate();
      if (this.valid) {
        this.submit(status, this.inner);
      }
    },
    onReset(){
      this.reset();
      this.$emit('reset');
    },
    onUpdateStatusesByClassification() {
      const statuses = this.statuses;
      const classification = this.values.classification;
      this.statusesByClassification = statuses;
      classification && client.statuses(classification).then(result => {
        const currents = this.fields.statusClient;
        const pick = map(map(get(result, 'result.items'), enumerationAdapter), 'code');
        const items = map(statuses, (item) => ({ ...item, disabled: !item.approval }));
        this.statusesByClassification = filter(items, ({ code, disabled }) => {
          return (!disabled && includes(pick, code)) || disabled && includes(compact(currents), code);
        });
      });

      if(this.fields.ckg[0]) {
        const currentClass = this.classifications.find((el) => el.code===this.fields.classification[0]);
        const filteredCKG = this.ckgList.filter((ckg) => ckg.ufClassification === currentClass.id);
        const currentCKG = filteredCKG.find((el) => el.code===this.fields.ckg[0]);
        if(!currentCKG) {
          this.fields.ckg = [''];
        }
      }
    },
    onUpdateAdvanceDiscount(value) {
      this.task.advanceDiscount = [!!value];
      this.advanceDiscount = !!value;
      this.$refs.form.validate();
    },
    onUpdateFiles(value) {
      this.files = value;
    },
    validateOnMount() {
      if (this.$refs.form) {
        this.$refs.form.validate();
      }
    },
  },
  watch: {
    'statuses': {
      handler() {
        // Произойдет только если статусы не успели загрузиться на момент рендера
        this.onUpdateStatusesByClassification();
        this.onReset();
      },
    },
    'values.classification': {
      immediate: true,
      handler() {
        this.onUpdateStatusesByClassification();
      },
    },
    'statusesByClassification': {
      handler(statuses) {
        if (!includes(map(statuses, 'code'), last(this.fields.statusClient))) {
          this.fields.statusClient.splice(-1, 1,'');
        }
      }
    }
  }
}
</script>

<style module lang="scss">
.root{}
</style>
