import {get, map, toString, head, values, isArray, uniq, uniqBy, join, mapValues, last, merge} from 'lodash';
import { clientAdapter, distributorAdapter, fileAdapter } from '@/store/utils';
import { clientItemAdapter } from '@/store/clients/utils';
import STATUSES from '@/api/enums/statuses';
import TYPES from '@/api/enums/types';

/**
 * Заглушка пустой заявки на основе адаптера клиента клиента "client.info"
 * @param client
 */
export const auditEmptyAdapter = (client) => {
  const id = get(client, 'id');
  return {
    inn: get(client, 'inn'),
    status: {
      code: id ? STATUSES.EDITING : STATUSES.CREATURE,
    },
    files: [],
    fields: {
      name: [get(client, 'name')],
      inn: [get(client, 'inn', '')],
      source: [get(client, 'source', '')],
      okved: [get(client, 'okved', '')],
      statusClient: [get(client, 'status.code', '')],
      address: [get(client, 'address', '')],
      check: [get(client, 'check', '')],
      violations: [get(client, 'violations', '')],
      innType: [get(client, 'innType', '')],
      phone: [get(client, 'phone', '')],
      site: [get(client, 'site', '')],
      comment: [''],
      distributors: [get(client, 'distributors', [])],
      clients: [get(client, 'clients', [])],
      person: [get(client, 'person', '')],
      distributorName: [get(client, 'distributorName', '')],
      authorDistributorName: [get(client, 'authorDistributorName', '')],
      sales: uniqBy([
        get(client, 'sales', []),
        get(client, 'nextSales', []),
      ], value => join(map(value, 'id'))),
    },
  }
};

/**
 * Поля для формы редактирования на основе клиента "audit.info"
 * @param item клиент
 * @param create признак создания
 * @returns {{site: unknown, address: unknown, clients: unknown, distributors: unknown, phone: unknown, name: unknown, inn: unknown, check: unknown, sales: unknown, statusClient: unknown}}
 */
export const auditFieldsAdapter = (item, create = false) => merge(mapValues({
  name: [
    get(item, 'name'),
  ],
  inn: get(item, 'displayProperties.clientIndependentInn.value'),
  status: uniq([
    get(item, 'displayProperties.status.value'),
  ]),
  statusCode: get(item, 'displayProperties.status.valueXmlId'),
  check: [
    get(item, 'displayProperties.check.valueXmlId')
  ],
  violations: [
    get(item, 'displayProperties.violations.valueXmlId')
  ],
  innType: [
    get(item, 'displayProperties.innType.valueXmlId')
  ],
  distributor: get(item, 'displayProperties.distributor.value'),
  distributorName: uniq([
    get(item, 'displayProperties.distributorName.displayValue'),
  ]),
  authorDistributorName: uniq([
    get(item, 'displayProperties.authorDistributorName.displayValue'),
  ]),
  clientName: [
    get(item, 'displayProperties.clientName.displayValue'),
  ],
  person: uniq([
    get(item, 'displayProperties.person.value'),
  ]),
  preview: uniq([
    get(item, 'previewText'),
  ]),
  dateClose: [get(item, 'displayProperties.dateClose.value')],
  comment: uniq([
    get(item, 'detailText'),
  ])
},(value) => create ? [last(value)] : value), {
  distributors: uniqBy([
    map(values(get(item, 'displayProperties.distributors.linkElementValue')), distributorAdapter),
    map(values(get(item, 'displayProperties.distributors.nextLinkElementValue')), distributorAdapter),
  ], value => join(map(value, 'id'))),
  clients: uniqBy([
    map(values(get(item, 'displayProperties.client.linkElementValue')), clientAdapter),
  ], value => join(map(value, 'id'))),
});

/**
 * Выборка полей заявки из структуры arResult
 * @param item
 * @returns {{date: string, comments: unknown[], inn: string, files: unknown[], client: {site: string, address: string, clients: minimist.Opts.unknown[], phone: string, name: string, inn: string, id: string, check: {code: string, name: string}, status: {code: string, name: string}}, id: string, check: {code: string, name: string}, type: {code: string, name: string}, fields: {site: minimist.Opts.unknown, address: minimist.Opts.unknown, clients: minimist.Opts.unknown, distributors: minimist.Opts.unknown, phone: minimist.Opts.unknown, name: minimist.Opts.unknown, inn: minimist.Opts.unknown, check: minimist.Opts.unknown, sales: minimist.Opts.unknown, statusClient: minimist.Opts.unknown}, user: string, distributor: (*), status: {code: string, name: string}}}
 */
export const auditItemAdapter = (item) => {
  const client = head(values((get(item, 'displayProperties.client.linkElementValue'))));
  const files = get(item, 'displayProperties.files.fileValue', []);
  const create = toString(get(item, 'displayProperties.type.value')) === TYPES.CREATE;
  return {
    id: toString(get(item, 'id')),
    date: toString(get(item, 'dateCreate')),
    inn: toString(get(item, 'displayProperties.inn.value')),
    nameClient: toString(get(item, 'displayProperties.clientName.displayValue')),
    check: {
      code: toString(get(item, 'displayProperties.check.valueXmlId')),
      name: toString(get(item, 'displayProperties.check.valueEnum')),
    },
    violations: {
      code: toString(get(item, 'displayProperties.violations.valueXmlId')),
      name: toString(get(item, 'displayProperties.violations.valueEnum')),
    },
    innType: {
      code: toString(get(item, 'displayProperties.innType.valueXmlId')),
      name: toString(get(item, 'displayProperties.innType.valueEnum')),
    },
    user: toString(get(item, 'createdByName')),
    status: {
      code: toString(get(item, 'displayProperties.status.valueXmlId')),
      name: toString(get(item, 'displayProperties.status.valueEnum')),
    },
    district: {
      code: toString(get(item, 'displayProperties.district.value')),
      name: toString(get(item, 'displayProperties.district.displayValue')),
    },
    region: {
      code: toString(get(item, 'displayProperties.region.value')),
      name: toString(get(item, 'displayProperties.region.displayValue')),
    },
    preview: toString(get(item, 'previewText')),
    comment: toString(get(item, 'detailText')),
    person: toString(get(item, 'displayProperties.person.value')),
    dateClose: toString(get(item, 'displayProperties.dateClose.value')),
    distributor: head(map(get(item, 'displayProperties.distributor.linkElementValue'), distributorAdapter)),
    distributorName: toString(get(item, 'displayProperties.distributorName.displayValue')),
    authorDistributorName: toString(get(item, 'displayProperties.authorDistributorName.displayValue')),
    files: map(isArray(files) ? files : [files], fileAdapter),
    filesDisplay: get(item, 'displayProperties.files.displayValue'),
    canEdit: get(item, 'canEdit', false),
    client: clientItemAdapter(client),
    fields: auditFieldsAdapter(item, create),
  };
};
