var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[(!_vm.isAdminView)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-fab-transition',[_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","absolute":"","right":"","fab":""},on:{"click":_vm.download}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-table-arrow-right')}})],1)],1)]}}],null,false,1597788260)},[_c('span',[_vm._v("Экспорт в excel")])]):_vm._e(),_c('v-data-table',{attrs:{"fixed-header":"","must-sort":"","loading-text":"Загрузка... Пожалуйста, подождите","loading":_vm.pending,"headers":[
        {text: 'Изменён', value: 'date'},
        {text: 'Клиент', value: 'name', sortable: false},
        {text: 'ИНН ', value: 'inn'},
        {text: '% скидки изменился с', value: 'sales[0]', sortable: false},
        {text: 'Текущий ', value: 'sales[1]', sortable: false},
        {text: 'Федеральный округ', value: 'districtName', sortable: false},
        {text: 'Классификация', value: 'classificationName', sortable: false} ],"mobile-breakpoint":0,"height":_vm.height,"items":_vm.items,"item-class":function (ref) {
            var _obj;

            var inn = ref.inn;
            return [_vm.$style.row, ( _obj = {}, _obj[_vm.$style.active] = inn === _vm.active, _obj )];
},"server-items-length":_vm.count,"options":_vm.options,"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 10, 20, 100, 500],
        itemsPerPageText: '',
      }},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.onClickRow},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('statuses-list-filter',{model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }