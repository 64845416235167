import {get, map, toString, head, values, isArray, uniq, uniqBy, join, mapValues, last, merge} from 'lodash';
import { saleAdapter, clientAdapter, distributorAdapter, commentAdapter, fileAdapter } from '@/store/utils';
import { clientItemAdapter } from '@/store/clients/utils';
import STATUSES from '@/api/enums/statuses';
import TYPES from '@/api/enums/types';

/**
 * Заглушка пустой заявки на основе адаптера клиента клиента "client.info"
 * @param client
 */
export const taskEmptyAdapter = (client) => {
  const id = get(client, 'id');
  return {
    inn: get(client, 'inn'),
    status: {
      code: id ? STATUSES.EDITING : STATUSES.CREATURE,
    },
    files: [],
    advanceDiscount: [false],
    advanceDiscountPeriod: [''],
    fields: {
      name: [get(client, 'name')],
      inn: [get(client, 'inn', '')],
      okved: [get(client, 'okved', '')],
      statusClient: [get(client, 'status.code', '')],
      recommendedStatus: [get(client, 'recommendedStatus.code', '')],
      bpConnected: [get(client, 'bpConnected', '')],
      address: [get(client, 'address', '')],
      classification: [get(client, 'classification.code', '')],
      ckg: [get(client, 'ckg.code', '')],
      classificationOkved: [get(client, 'classificationOkved')],
      phone: [get(client, 'phone', '')],
      site: [get(client, 'site', '')],
      comment: [''],
      distributors: [get(client, 'distributors', [])],
      clients: [get(client, 'clients', [])],
      advanceDiscountPeriod: [get(client, 'advanceDiscountPeriod', '')],
      sales: uniqBy([
        get(client, 'sales', []),
        get(client, 'nextSales', []),
      ], value => join(map(value, 'id'))),
      source: [get(client, 'source')],
    },
  }
};

/**
 * Поля для формы редактирования на основе клиента "task.info"
 * @param item клиент
 * @param create признак создания
 * @returns {{site: unknown, address: unknown, clients: unknown, distributors: unknown, phone: unknown, name: unknown, inn: unknown, classification: unknown, sales: unknown, statusClient: unknown}}
 */
export const taskFieldsAdapter = (item, create = false) => {
  return merge(mapValues({
    name: uniq([
      get(item, 'name'),
      get(item, 'nextName'),
    ]),
    okved: uniq([
      get(item, 'displayProperties.okved.value'),
      get(item, 'displayProperties.okved.nextValue'),
    ]),
    inn: uniq([
      get(item, 'code'),
      get(item, 'nextCode'),
    ]),
    statusClient: uniq([
      get(item, 'displayProperties.status.value'),
      get(item, 'displayProperties.status.nextValue'),
    ]),
    address: uniq([
      get(item, 'displayProperties.address.value'),
      get(item, 'displayProperties.address.nextValue'),
    ]),
    classification: uniq([
      get(item, 'displayProperties.classification.value'),
      get(item, 'displayProperties.classification.nextValue'),
    ]),
    ckg: uniq([
      get(item, 'displayProperties.ckg.value'),
      get(item, 'displayProperties.ckg.nextValue'),
    ]),
    phone: uniq([
      get(item, 'displayProperties.phone.value'),
      get(item, 'displayProperties.phone.nextValue'),
    ]),
    site: uniq([
      get(item, 'displayProperties.site.value'),
      get(item, 'displayProperties.site.nextValue'),
    ]),
    sales: uniqBy([
      map(get(item, 'sales'), saleAdapter),
      map(get(item, 'nextSales'), saleAdapter),
    ], value => join(map(value, 'id')))
  },(value) => create ? [last(value)] : value), {
    distributors: uniqBy([
      map(values(get(item, 'displayProperties.distributors.linkElementValue')), distributorAdapter),
      map(values(get(item, 'displayProperties.distributors.nextLinkElementValue')), distributorAdapter),
    ], value => join(map(value, 'id'))),
    clients: uniqBy([
      map(values(get(item, 'displayProperties.related.linkElementValue')), clientAdapter),
      map(values(get(item, 'displayProperties.related.nextLinkElementValue')), clientAdapter),
    ], value => join(map(value, 'id'))),
  });
};

/**
 * Выборка полей заявки из структуры arResult
 * @param item
 * @returns {{date: string, comments: unknown[], inn: string, files: unknown[], client: {site: string, address: string, clients: minimist.Opts.unknown[], phone: string, name: string, inn: string, id: string, classification: {code: string, name: string}, status: {code: string, name: string}}, id: string, classification: {code: string, name: string}, type: {code: string, name: string}, fields: {site: minimist.Opts.unknown, address: minimist.Opts.unknown, clients: minimist.Opts.unknown, distributors: minimist.Opts.unknown, phone: minimist.Opts.unknown, name: minimist.Opts.unknown, inn: minimist.Opts.unknown, classification: minimist.Opts.unknown, sales: minimist.Opts.unknown, statusClient: minimist.Opts.unknown}, user: string, distributor: (*), status: {code: string, name: string}}}
 */
export const taskItemAdapter = (item) => {
  const client = head(values((get(item, 'displayProperties.client.linkElementValue'))));
  const files = get(item, 'displayProperties.files.fileValue', []);
  const create = toString(get(item, 'displayProperties.type.value')) === TYPES.CREATE;
  let data = {
    id: toString(get(item, 'id')),
    date: toString(get(item, 'timestampX')),
    inn: toString(get(item, 'displayProperties.inn.value')),
    nameClient: toString(get(item, 'displayProperties.clientName.displayValue')),
    classification: {
      code: toString(get(item, 'displayProperties.classification.value')),
      name: toString(get(item, 'displayProperties.classification.displayValue')),
    },
    ckg: {
      code: toString(get(item, 'displayProperties.ckg.value')),
      name: toString(get(item, 'displayProperties.ckg.displayValue')),
    },
    user: toString(get(item, 'displayProperties.userId.displayValue')),
    status: {
      code: toString(get(item, 'displayProperties.lastStatus.value')),
      name: toString(get(item, 'displayProperties.lastStatus.displayValue')),
    },
    newStatus: {
      code: toString(get(item, 'displayProperties.clientNewStatus.value')),
      name: toString(get(item, 'displayProperties.clientNewStatus.displayValue')),
    },
    district: {
      code: toString(get(item, 'displayProperties.district.value')),
      name: toString(get(item, 'displayProperties.district.displayValue')),
    },
    region: {
      code: toString(get(item, 'displayProperties.region.value')),
      name: toString(get(item, 'displayProperties.region.displayValue')),
    },
    type: {
      code: toString(get(item, 'displayProperties.type.value')),
      name: toString(get(item, 'displayProperties.type.displayValue')),
    },
    advanceDiscount: [get(item, 'displayProperties.advanceDiscount.valueXmlId')==='Y'],
    advanceDiscountPeriod: [get(item, 'properties.advanceDiscountPeriod.valueXmlId')],
    distributor: head(map(get(item, 'displayProperties.distributor.linkElementValue'), distributorAdapter)),
    files: map(isArray(files) ? files : [files], fileAdapter),
    comments: map(get(item, 'comments'), commentAdapter),
    canEdit: get(item, 'canEdit', false),
    history: map(get(item, 'statuses'), (item) => ({
      id: toString(get(item, 'id')),
      to: toString(get(item, 'displayProperties.status.displayValue')),
      user: toString(get(item, 'displayProperties.userId.user.name')),
      login: toString(get(item, 'displayProperties.userId.user.login')),
      date: toString(get(item, 'dateCreate')),
    })),
    client: clientItemAdapter(client),
    fields: taskFieldsAdapter(client, create),
  };
  data.fields.classificationOkved = [get(item, 'classificationOkved')];
  data.fields.source = [get(item, 'displayProperties.source.value')];
  return data;
};
