<template>
  <v-text-field
      ref="input"
      v-mask="'############'"
      dense
      hide-details="auto"
      :label="label"
      :outlined="outlined"
      :value="value"
      :rules="rules"
      :loading="pending"
      :disabled="disabled"
      @input="onInput"
  />
</template>

<script>
import { mask } from 'vue-the-mask';
import { find, get, isNaN, map, pick, values } from 'lodash';
import client from '@/store/clients/api';

export default {
  name: 'InnField',
  directives: { mask },
  props: {
    label: { type: String, default: null },
    value: { type: String, default: '' },
    check: { type: Array, default: () => [] },
    outlined: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    not: { type: Array, default: () => []},
    distributor: { type: String, default: '' },
  },
  data() {
    return {
      pending: false,
      items: [],
      rules: values(pick({
        required: val => !!val || 'Обязательно для заполнения',
        inn: val => !!(!val || ([10, 12].includes(val.length) && val)) || 'ИНН должен содержать 10 или 12 цифр',
        not: () => !!this.items.length ||'Введенный ИНН не найден',
        relatedByDistributor: () => (!this.distributor || (!!this.distributor && !!this.items.length)) ||'Клиент не привязан к данному дистрибутору',
        related: () => !!find(this.items, { related: true }) || 'Клиент к вам не привязан',
        notRelated: () => !!find(this.items, { related: false }) || 'Клиент уже к вам привязан',
        inner: () => !!find(this.items, { inner: true }) || 'Клиент не зарегистрирован в системе',
        notInner: () => !!find(this.items, { inner: false }) || 'Клиент уже зарегистрирован в системе',
        duplicate: val => !this.not.includes(val) || 'Дубликат привязки',
      }, this.check)),
    };
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
      this.fetch(value);
    },
    fetch(inn) {
      if ((inn && [10, 12].includes(inn.length) && !isNaN(+inn))) {
        this.pending = true;
        if(this.distributor) {
          client.list(1,1,{},{inn: inn, distributors: this.distributor}).then((result) => {
            this.pending = false;
            const success = !get(result, 'result.error') && result.retval.length>0;
            this.items = success ? map(get(result, 'result.items'), (item) => ({
              id: get(item, 'id', ''),
              code: get(item, 'code', ''),
              name: get(item, 'name', ''),
              classification: get(item, 'classification', ''),
              status: get(item, 'status', ''),
              district: get(item, 'district', ''),
              address: get(item, 'address', ''),
              phone: get(item, ['phone', '0', 'value'], get(item, 'phone', '')),
              related: get(item, 'isRelated', false),
              inner: get(item, 'isInner', true),
              okved: get(item, 'okved', ''),
              source: get(item, 'source', ''),
            })) : [];
            this.$emit('update', this.items);

            if (result.result.error) throw {
              error: 'Ошибка получения данных',
              error_description: get(result, 'result.message', 'Сервис не доступен, попробуйте позже'),
            };
          }).catch(error => {
            this.$snack({
              text: get(error, 'error_description', 'Сервис не доступен, попробуйте позже'),
              pos: 'top-center',
              actionText: 'Понятно',
            });
          });
        }
        else {
          client.find(inn).then((result) => {
            this.pending = false;
            const success = !get(result, 'result.error');
            this.items = success ? map(get(result, 'result.items'), (item) => ({
              id: get(item, 'id', ''),
              code: get(item, 'code', ''),
              name: get(item, 'name', ''),
              classification: get(item, 'classification', ''),
              status: get(item, 'status', ''),
              district: get(item, 'district', ''),
              address: get(item, 'address', ''),
              phone: get(item, ['phone', '0', 'value'], get(item, 'phone', '')),
              related: get(item, 'isRelated', false),
              inner: get(item, 'isInner', false),
              okved: get(item, 'okved', ''),
              innClassesOkved: get(item, 'innClassesOkved', false),
              source: get(item, 'source', ''),
            })) : [];
            this.$emit('update', this.items);

            if (!success) throw {
              error: 'Ошибка получения данных',
              error_description: get(result, 'result.message', 'Сервис не доступен, попробуйте позже'),
            };
          }).catch(error => {
            this.$snack({
              text: get(error, 'error_description', 'Сервис не доступен, попробуйте позже'),
              pos: 'top-center',
              actionText: 'Понятно',
            });
          });
        }
      }
    },
  },
  watch: {
    value() {
      this.$refs.input.validate();
    },
    items: {
      deep: true,
      handler() {
        this.$refs.input.validate();
      }
    },
  },
  beforeMount() {
    this.fetch(this.value);
  },
  mounted() {
    // Грязный хак для проверки валиднасти inn по монтированию
    this.$nextTick(() => {
      setTimeout(() => {
        this.$refs.input.focus();
        this.$refs.input.blur();
      }, 200);
    });
  }
}
</script>

<style module lang="scss"></style>
