<template>
  <tr :class="$style.root">
    <td>
      <div :class="$style.control">
        <date-picker
            v-model="date"
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-text-field
            v-model="name"
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-text-field
            v-model="inn"
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td></td>
    <td></td>
    <td>
      <div :class="$style.control">
        <v-select
            v-model="districtName"
            :items="districtList"
            hideSelected
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-select
            v-model="classificationName"
            :items="classificationList"
            hideSelected
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
  </tr>
</template>

<script>
import { get, debounce, pickBy } from 'lodash';
import DatePicker from '@/components/DatePickerRange/DatePickerRange';
import {mapGetters} from "vuex";

export default {
  name: 'StatusesListFilter',
  components: {
    DatePicker
  },
  props: {
    value: { type: Object },
  },
  data() {
    return {
      date: get(this.value, 'date', ''),
      name: get(this.value, 'name', ''),
      inn: get(this.value, 'inn', ''),
      districtName: get(this.value, 'districtName', ''),
      classificationName: get(this.value, 'classificationName', ''),
    }
  },
  computed: {
    ...mapGetters({
      districtList: 'clients/districts',
      classificationList: 'clients/classifications',
    })
  },
  methods: {
    onChange: debounce(function() {
      this.$emit('input', pickBy({
        date: this.date,
        name: this.name,
        inn: this.inn,
        districtName: this.districtName,
        classificationName: this.classificationName,
      }, Boolean));
    }, 600),
  },
}
</script>

<style module lang="scss">
.root {
  background: none !important;
  .control {
    width: 150px;
  }
}
</style>
