import { toString, forEach, pickBy, keys, map } from 'lodash';
import { Api } from '@/api';

export class Task extends Api {
  item(id) {
    return this.request('tasks.info', {
      params: {
        id: toString(id),
      }
    });
  }
  /**
   * Получение списка доступных клиентов
   * @param page
   * @param size
   * @param sort
   * @param filter
   * @returns {Promise<*>}
   */
  list(page = 1, size = -1, sort = {}, filter = {}) {
    const params = {
      page: toString(page),
      size: toString(size),
    };
    forEach(sort, (value, key) => (params[`sort[${key}]`] = value));
    forEach(filter, (value, key) => params[`filter[${key}]`] = value);
    return this.request('tasks.list', {
      params,
    });
  }
  statuses() {
    return this.request('tasks.listStatuses');
  }
  newStatuses() {
    return this.request('tasks.listNewStatuses');
  }
  advanceDiscountPeriods() {
    return this.request('tasks.listAdvanceDiscountPeriods');
  }
  regions() {
    return this.request('clients.listRegions');
  }
  types() {
    return this.request('tasks.listTypes');
  }
  update(fields) {
    let data = pickBy({
      id: toString(fields.id),
      name: toString(fields.name),
      okved: toString(fields.okved),
      status: toString(fields.status),
      classification: toString(fields.classification),
      ckg: toString(fields.ckg),
      inn: toString(fields.inn),
      address: toString(fields.address),
      phone: toString(fields.phone),
      site: toString(fields.site),
      statusClient: toString(fields.statusClient),
      type: toString(fields.type),
      comment: toString(fields.comment),
      files: fields.files ? map(fields.files, (id) => toString(id)) : null,
      related: fields.clients ? map(fields.clients, (id) => toString(id)) : null,
      distributor: toString(fields.distributor),
      distributors: !fields.distributor && fields.distributors ? map(fields.distributors, (id) => toString(id)) : null,
      advanceDiscountPeriod: fields.advanceDiscountPeriod,
      source: fields.source,
    }, Boolean);

    data.advanceDiscount = !!fields.advanceDiscount;

    const url = data.id && data.status && keys(data).length === 2 ? '/tasks.updateStatus' : '/tasks.update';
    return this.request(url, {
      method: 'POST',
      data: this.toFormData(data),
    });
  }
  comment(id, message){
    return this.request('tasks.addComment', {
      method: 'POST',
      data: this.toFormData({
        id: toString(id),
        message: toString(message),
      }),
    })
  }
  distributors(filter = {}) {
    const params = {};
    forEach(filter, (value, key) => params[`filter[${key}]`] = value);
    return this.request('tasks.listDistributors', {
      params,
    });
  }
}

export default new Task();
