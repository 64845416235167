import {get, toString} from 'lodash';
/**
 * Выборка полей клиента из структуры arResult
 * @param item
 * @returns {{site: string, address: string, clients: unknown[], phone: string, name: string, inn: string, id: string, classification: {code: string, name: string}, status: {code: string, name: string}}}
 */
export const statusItemAdapter = (item) => ({
  id: toString(get(item, 'id')),
  name: toString(get(item, 'name')),
  inn: toString(get(item, 'inn')),
  date: toString(get(item, 'date')),
  sales: [
    toString(get(item, ['saleOld', '0', 'salePercent'])),
    toString(get(item, ['saleNew', '0', 'salePercent']))
  ], // TODO: заглушка взять из api
  districtName: toString(get(item, 'districtName')),
  classificationName: toString(get(item, 'classificationName')),
});
